<template>
  <div>
    <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center">
      <b-spinner class="mr-2" />
      <small>
        Carregando
      </small>
    </div>
    <b-table
      v-if="logs.length > 0 && !loading"
      responsive
      :fields="campos"
      :items="logs"
    >
      <template #cell(actions)="row">
        <b-btn
          @click="row.toggleDetails"
          class="mr-2"
          size="sm"
          variant="transparent"
        >
          <a href="#" class="text-secondary">
            <b-icon-card-list></b-icon-card-list>
            {{ row.detailsShowing ? "Esconder" : "Mostrar" }} Detalhes
          </a>
        </b-btn>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
      </template>

      <template #cell(data)="{item}">
        {{ item.data | moment("DD/MM/YYYY HH:mm:ss") }}

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
      </template>

      <template #row-details="row">
        <b-card>
          <b-card-title
            v-if="row.item.status == 'Alterado'"
            class="text-center"
          >
            Alterações
          </b-card-title>

          <ExibeDados :namedKeys="namedKeys" :data="row.item.alteracoes" />

          <!-- <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button> -->
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import LogsLib from "../../../libs/LogsLib";
import ExibeDados from "./ExibeDados.vue";
export default {
  components: {
    ExibeDados,
  },
  props: {
    dataDe: [Date, String],
    dataAte: [Date, String],
    user_id: [String, Number],
    tabela: [String],
    id_externo: [String, Number],
    namedKeys: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      logs: [],
      loading: false,
      campos: [
        {
          key: "id",
          label: "Código",
          sortable: true,
        },
        {
          key: "data",

          sortable: true,
        },

        {
          key: "status",

          sortable: true,
        },

        // {
        //   key: "alteracoes",

        //   sortable: true,

        // },
        {
          key: "usuarioNome",
          label: "Usuário",
          class: "text-center",
        },

        { key: "actions", label: "", class: "" },
      ],
    };
  },
  methods: {
    async buscarLogs() {
      this.loading = true;
      this.logs = (
        await LogsLib.get(
          this.dataDe,
          this.dataAte,
          this.user_id,
          this.tabela,
          this.id_externo
        )
      ).map((log) => {
        return {
          ...log,
          status:
            log.status == "added"
              ? "Adicionado"
              : log.status == "changed"
              ? "Alterado"
              : log.status == "deleted"
              ? "Excluído"
              : "",
        };
      });

      this.loading = false;
    },
  },
  async mounted() {
    await this.buscarLogs();
  },
};
</script>

<style></style>
