<template>
  <div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped" style="cursor: pointer">
          <thead class="thead">
            <tr>
              <th scope="col"></th>
              <th scope="col">Horário</th>
              <th scope="col" class="text-center">Paciente</th>

              <th scope="col"></th>
              <th scope="col" class="text-center">Retorno</th>
              <th scope="col" class="text-center">Dilatação</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <!-- <draggable
          v-if="Array.isArray(horariosFiltrados )&& horariosFiltrados.length > 0"
            v-model="horariosFiltrados"
            tag="tbody"
            item-key="order"
            class="draggable"
          > -->
          <tr
            v-for="element in horariosFiltrados"
            :key="element.id"
            class="text-center"
            :class="
              element.retorno == 1
                ? 'table-danger'
                : element.confirmou
                ? 'table-success'
                : ''
            "
          >
            <td scope="row" style="cursor: grab">
              <i class="fas fa-bars"></i>
            </td>
            <td scope="row">
              <b-form-input
                v-model="element.horario"
                size="small"
                type="time"
                step="0"
                locale="br"
                @change="updated(horarios.indexOf(element))"
              ></b-form-input>
            </td>
            <td scope="row" class="text-center">{{ element.paciente }}</td>

            <td scope="row">
              <b-btn
                variant="transparent"
                v-if="element._rowStatus"
                v-b-popover.hover.top="`Esse horário ainda não foi salvo!`"
                title="Atenção!"
              >
                <i class="fas fa-circle" style="color: #868b8e"></i>
              </b-btn>
            </td>
            <td scope="row" class="text-center">
              <b-form-checkbox
                @change="onChangeHorario(element)"
                switch
                v-model="element.retorno"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
              <!-- <i :class="['text-center',{'fas fa-check': element.retorno == 1}]"></i> -->
            </td>
            <td scope="row" class="text-center">
              <b-form-checkbox
                switch
                v-model="element.dilatacao"
                @change="updated(horarios.indexOf(element))"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </td>
            <td scope="row">
              <b-btn
                size="small"
                variant="transparent"
                @click="apagarHorario(horarios.indexOf(element))"
              >
                <i class="fas fa-times"></i>
              </b-btn>
            </td>
          </tr>
          <!-- </draggable> -->

          <tr v-if="horariosFiltrados.length <= 0">
            <td colspan="7">
              <h4 class="text-muted text-center">
                Nenhum horário foi gerado...
              </h4>
            </td>
          </tr>
        </table>

        <!-- <b-table
          :items="horariosFiltrados"
          :fields="campos"
          responsive="sm"
          :busy="loading"
          stacked="sm"
        >
          <template #table-busy class="text-center">
            <b-spinner />
          </template>

          <template #cell(status)="row">
            <b-btn
              variant="transparent"
              v-if="row.item._rowStatus"
              v-b-popover.hover.top="`Esse horário ainda não foi salvo!`"
              title="Atenção!"
            >
              <i class="fas fa-circle" style="color: #868B8E;"></i>
            </b-btn>
          </template>
          <template #cell(horario)="row">
            <b-input
              @keypress.enter="salvarHorario()"
              type="time"
              v-model="row.item.horario"
              @change="updated(horarios.indexOf(row.item))"
            ></b-input>
          </template>

          <template #cell(dilatacao)="{item}">
            <b-form-checkbox
              :value="1"
              :unchecked-value="0"
              v-model="item.dilatacao"
              @change="updated(horarios.indexOf(item))"
              switch
            ></b-form-checkbox>
          </template>

          <template #cell(actions)="row">
            <b-btn
              size="small"
              variant="transparent"
              @click="apagarHorario(horarios.indexOf(row.item))"
            >
              <i class="fas fa-times"></i>
            </b-btn>
          </template>
        </b-table> -->
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="bottom-actions" style="position: unset">
          <hr class="mb-2" />
          <b-btn variant="primary" @click="addHorario" class="float-left mt-4">
            <i class="fas fa-plus"></i> Adicionar Horário
          </b-btn>
          <b-button
            variant="primary"
            size="md"
            class="mt-4"
            @click="salvarHorario()"
            :disabled="loading"
          >
            <span v-if="!loading"><i class="fa fa-save"></i> Salvar</span>
            <span v-if="loading"
              ><b-spinner small size="sm" grow /> Salvando...</span
            ></b-button
          >
          <b-button
            variant="secondary"
            size="md"
            class="mt-4 ml-2"
            @click="fecharModal()"
            ><i class="fa fa-times"></i> Cancelar</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HorariosLib from "@/libs/HorariosLib";
import moment from 'moment';
// import draggable from "vuedraggable";
export default {
  components: {
    // draggable,
  },
  data() {
    return {
      lista: [],

      campos: [
        {
          key: "horario",
          label: "Horário",
          sortable: true,
          class: "text-center",
        },
        {
          key: "paciente",
          label: "Paciente",
          class: "text-center",
          sortable: true,
        },

        { key: "cpf", label: "CPF", class: "text-center" },
        { key: "idade", label: "Idade", class: "text-center" },
        { key: "obs", label: "Observações", class: "text-center" },
        { key: "status", label: "", class: "text-center" },
        { key: "dilatacao", label: "Dilatação", class: "text-center" },
        { key: "actions", label: "", class: "" },
      ],
      loading: false,
      horarios: [],
      horariosAdded: 0,
      debounce: false,
    };
  },
  props: {
    id_agenda: Number,
    horariosProp: { type: Array, default: () => [] },
  },
  async mounted() {
    if (this.horariosProp.length > 0) {
      // console.log('entrou 01', this.horariosProp.length);
      this.horariosProp.sort((a, b) => { return moment(moment().format("YYYY-MM-DD ") + a.horario).isBefore(moment().format("YYYY-MM-DD ") + b.horario)? -1:1} )
      .forEach((h) => {
        this.horarios.push(Object.assign({}, h));
      });
      //this.horarios = this.horarios.sort((a, b) => { return moment(moment().format("YYYY-MM-DD ") + a.horario).isBefore(moment().format("YYYY-MM-DD ") + b.horario)? -1:1} )

    }
      // console.log('entrou 02', this.horarios);
  },

  methods: {
    fecharModal() {
      this.$bvModal.hide("modal-horarios-table");
    },
    onChangeHorario(horario) {
      // if (!horario._rowStatus) {
      //   }
      // console.log('alt',horario)
        this.$set(horario, "_rowStatus", "updated");
    },
    updateItemOrder(idx, order) {
      // console.log("indexHorario", idx);
      if (idx >= 0) {
        // this.horarios[idx].order = order
        if (!this.horarios[idx]._rowStatus) {
          this.$set(this.horarios[idx], "_rowStatus", "updated");
        }
        this.$set(this.horarios[idx], "order", order);
      }
    },
    addHorario() {
      this.horarios.push({
        id_agenda: this.id_agenda,
        horario: "",
        id_paciente: null,
        paciente: null,
        telefone: null,
        idade: null,
        cpf: null,
        obs: null,
        retorno: null,
        agendado: 0,
        compareceu: 0,
        procedimento: 0,
        confirmou: 0,
        dilatacao: 0,
        questionario: {},
        id_horario_retorno: null,
        _rowStatus: "added",
        order: this.horarios.length,
      });
      this.horariosAdded++;
    },

    updated(indexHorario) {
      // console.log('horario atu',indexHorario);
      if (typeof indexHorario != "undefined" && indexHorario.toString() != "") {
        if (this.horarios[indexHorario]._rowStatus === "added") {
          return;
        }
        this.$set(this.horarios[indexHorario], "_rowStatus", "updated");
      }
    },
    async carregaHorarios() {
      this.loading = true;
      // try {
      //   console.log("id_agenda: ", this.id_agenda);
      //   this.lista = await HorariosLib.get(this.id_agenda);
      // } catch (error) {
      //   console.warn(error);
      // }
      // this.loading = false;
    },
    validar() {
      let validacao = { validado: true, erro: [] };

      // if (!this.id_agenda) {
      //   validacao.validado = false;
      //   validacao.erro.push(
      //     "Agenda ainda não foi gerada, gere para criar os horários automaticamente!"
      //   );
      // }
      // if (!this.lista) {
      //   validacao.validado = false;
      //   validacao.erro.push("Erro ao carregar os horários");
      // }

      return validacao;
    },
    apagarHorario(index) {
      this.toastConfirmacao(() => {
        let horario = this.horarios[index];
        if (horario) {
          this.horariosAdded--;
          if (horario._rowStatus && horario._rowStatus == "added") {
            this.horarios.splice(index, 1);
            return;
          }
          this.$set(this.horarios[index], "_rowStatus", "deleted");
        }
      });
    },
    async salvarHorario() {
      let validar = this.validar();
      if (validar.validado) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja modificar este horário?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Modificar!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                // this.$emit('horariosAdded', this.horariosAdded)
                this.$emit("personalizouHorarios", {
                  horarios: this.horarios,
                  horariosAdded: this.horariosAdded,
                });
                this.loading = true;

                this.$swal.fire({
                  title: "Horário(s) modificado(s) com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });

                // this.carregaHorarios();
              } catch (error) {
                this.$swal.fire({
                  title: "Erro ao modificar horário!\n" + error,
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire({
          title:
            "Desculpe, essa agenda ainda não tem horários! :(\n\n" +
            validar.erro,
          icon: "error",
          toast: true,
          timer: 4654654654564,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    },
  },
  computed: {
    horariosFiltrados: {
      get() {
        // console.log('comp1',this.horarios)

        let ret = this.horarios
          .filter((horario) => {
            if (horario._rowStatus && horario._rowStatus === "deleted") {
              return false;
            }
            return true;
          })
        // console.log('comp2',ret)
          return ret;
          // .sort((a, b) => { return moment(moment().format("YYYY-MM-DD ") + a.horario).isBefore(moment().format("YYYY-MM-DD ") + b.horario)? -1:1} );
      },
      set(newV) {
        newV
        // console.log("hoarioss", ...newV.map((hr) => hr));
        // newV.forEach((hr, idx) => {
        //   this.updateItemOrder(
        //     this.horarios.findIndex((h) => h.id == hr.id),
        //     idx
        //   );
        // });
        //console.log('indexes', [this.horarios.findIndex(h=>...newV.map(hr=>hr.id))]);
      },
    },
  },
  watch: {
    horariosProp() {
      // console.log(
      //   "o horario prop mudou!",
      //   this.horariosProp,
      //   newVal,
      //   this.horarios
      // );
      //console.log(`a length do horarioProp ${this.horariosProp.length} a length do horario ${this.horario.length} e executando um (this.horarios.filter(h=> !h._rowStatus&&!this.horariosProp.some(horario=>h.id == horario.id)).length>0 o resulto é ${(this.horarios.filter(h=> !h._rowStatus&&!this.horariosProp.some(horario=>h.id == horario.id)).length>0)}`);
      let atualiza = async () => {
        let adicionando = [
          ...this.horarios.filter(
            (h) => h._rowStatus && h._rowStatus == "added"
          ),
        ];
        // console.log("horarios que estava adicionando", adicionando);
        this.horarios = [];
        await this.$nextTick();
        this.horariosProp.forEach((h) => {
          this.horarios.push(Object.assign({}, h));
        });
        if (adicionando.length > 0) {
          //this.horarios.push(...adicionando);
        }
      };

      if (
        this.horariosProp.length != this.horarios.length ||
        !this.horariosProp.some((horario) =>
          this.horarios.some((h) => !h._rowStatus && h.id == horario.id)
        ) ||
        this.horarios.some((h) =>
          this.horariosProp.some(
            (horario) => !h._rowStatus && h.id == horario.id
          )
        )
      ) {
        atualiza();
      }
    },
  },
};
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
