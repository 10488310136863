import { render, staticRenderFns } from "./NotificacaoConfig.vue?vue&type=template&id=5136c414&scoped=true&"
import script from "./NotificacaoConfig.vue?vue&type=script&lang=js&"
export * from "./NotificacaoConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5136c414",
  null
  
)

export default component.exports