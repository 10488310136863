<template>
  <div class="container">
    <!-- {{agenda.horarios.length}} -->
    <!-- {{ EntidadesOptions }} -->

    <b-form @submit.prevent="salvarAgenda()">
      <div class="row">
        <!-- {{ agenda.horarios }}
        {{ this.horarioInicial }}
        {{ this.agenda.hora_inicio }}

        {{ this.horarioInicial != this.agenda.hora_inicio }} -->

        <div class="col-12 col-sm-7">
          <div class="row">
            <div class="col-12 col-md-2 mt-4 text-center" v-if="agenda.id">
              <h4 class="text-center">
                Código
                
              </h4>
              <h4>
                  <small>
                    {{ agenda.id }}
                  </small>
                </h4>
            </div>
            <div class="col-12 col-md-6 mt-4">
              <h4 class="d-flex justify-item-start">Data</h4>

              <b-form-input
                type="date"
                class="form-control"
                v-model="$v.agenda.data.$model"
                :state="validateState('data')"
              />
              <b-form-invalid-feedback>
                Por favor, insira uma data.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="row mt-4 text-nowrap">
            <div class="col-sm-12 col-md-4">
              <label class="d-flex justify-item-start">Hora Início</label>
              <b-input-group class="mb-3">
                <b-form-input
                  v-model="$v.agenda.hora_inicio.$model"
                  @keydown="verificarHorarios"
                  @focusout="podeEditar = false"
                  v-mask="'##:##'"
                  type="text"
                  ref="hora_inicio"
                  placeholder="HH:mm"
                  :state="validateState('hora_inicio')"
                  
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="$v.agenda.hora_inicio.$model"
                    button-only
                    right
                    locale="pt-br"
                  ></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="d-flex justify-item-start">Intervalo</label>
              <b-form-input
                type="text"
                class="form-control"
                :readonly="!isNaN(agenda.id)"
                v-model="$v.agenda.intervalo.$model"
                ref="intervalo"
                @keydown="verificarHorarios"
                @focusout="podeEditar = false"
                :state="validateState('intervalo')"
              />
              <b-form-invalid-feedback>
                Por favor, insira um intervalo.
              </b-form-invalid-feedback>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="d-flex justify-item-start">Qtd. Espera</label>
              <b-form-input
                type="number"
                class="form-control"
                v-model="$v.agenda.espera_qtd.$model"
                no-wheel
                :state="validateState('espera_qtd')"
                
              />
            </div>
          </div>

          <div class="row mt-4 text-nowrap">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="d-flex justify-item-start">Qtd. Consulta</label>
              <b-form-input
                type="number"
                @keydown="verificarHorarios"
                @focusout="podeEditar = false"
                :readonly="!isNaN(agenda.id)"
                class="form-control"
                v-model="$v.agenda.consulta_qtd.$model"
                ref="consulta_qtd"
                :state="validateState('consulta_qtd')"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="d-flex justify-item-start">Qtd. Retorno</label>
              <b-form-input
                type="number"
                class="form-control"
                ref="retorno_qtd"
                v-model="$v.agenda.retorno_qtd.$model"
                :readonly="!isNaN(agenda.id)"
                @keydown="verificarHorarios"
                @focusout="podeEditar = false"
                :state="validateState('retorno_qtd')"
              />
            </div>
            <div class="col-7 col-sm-12 col-md-4">
              <h4 class="d-flex justify-item-start">Total</h4>
              <b-form-input
                type="text"
                class="form-control"
                readonly
                v-model="$v.agenda.total.$model"
                :state="validateState('total')"
              />
              <b-form-invalid-feedback>
                O total não pode ser menor que 1.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-4">
              <h4 class="d-flex justify-item-start">Observações</h4>
              <b-form-textarea
                v-model="agenda.obs"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-5">
          <div class="row">
            <div class="col-12 col-sm-10 mt-4">
              <h5 class="">
                <b-spinner v-if="loading" small size="sm" grow />
                Especialidade
              </h5>

              <v-select
                :options="especialidadesOptions"
                v-model="$v.agenda.especialidade.$model"
                :reduce="(espec) => espec.id"
                @change="carregaEntidades()"
                type="text"
                label="especialidade"
                :state="validateState('especialidade')"
              >
                <template #option="item">
                  <small class="font-sm">{{ item.especialidade }}</small>
                </template>
                <template #search="{ attributes, events }">
                  <input
                    class="font-sm"
                    :class="[
                      'vs__search rounded',
                      typeof validateState('especialidade') == 'boolean' &&
                      !validateState('especialidade')
                        ? 'isInvalid'
                        : '',
                    ]"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Nenhum resultado para <em>{{ search }}</em
                    >.
                  </template>
                  <em style="opacity: 0.5" v-else
                    >Não encontramos nenhuma especialiade 😕.</em
                  >
                </template>
              </v-select>
              <b-form-invalid-feedback
                :force-show="
                  typeof validateState('especialidade') == 'boolean' &&
                  !validateState('especialidade')
                "
              >
                Selecione uma especialiade.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-10 mt-4">
              <h5 class="">
                <b-spinner v-if="loading" small size="sm" grow />
                Anamnese
              </h5>

              <v-select
                :options="anamneses"
                v-model="agenda.anamnese"
                :reduce="(anamnese) => anamnese.id"
                type="text"
                label="nome"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search rounded"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Nenhum resultado para <em>{{ search }}</em
                    >.
                  </template>
                  <em style="opacity: 0.5" v-else
                    >Não encontramos nenhuma anamnese 😕.</em
                  >
                </template>
              </v-select>
              <!-- <b-form-invalid-feedback
                :force-show="
                  typeof validateState('anamnese') == 'boolean' &&
                    !validateState('anamnese')
                "
              >
                Selecione uma anamnese.
              </b-form-invalid-feedback> -->
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-sm-10">
              <h5 class="">
                <b-spinner v-if="loading" small size="sm" grow /> Médico
              </h5>

              <v-select
                :options="EntidadesOptions"
                v-model="$v.agenda.entidade_id.$model"
                :reduce="(entidade) => entidade.id"
                label="razao_social"
                type="text"
                :state="validateState('entidade_id')"
              >
                <template #search="{ attributes, events }">
                  <input
                    :class="[
                      'vs__search rounded',
                      typeof validateState('entidade_id') == 'boolean' &&
                      !validateState('entidade_id')
                        ? 'isInvalid'
                        : '',
                    ]"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Nenhum resultado para <em>{{ search }}</em
                    >.
                  </template>
                  <em style="opacity: 0.5" v-else
                    >Não encontramos nenhum médico com essa especialiade 😕.</em
                  >
                </template>
              </v-select>
              <b-form-invalid-feedback
                :force-show="
                  typeof validateState('entidade_id') == 'boolean' &&
                  !validateState('entidade_id')
                "
              >
                Selecione um médico.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="col-10 mt-3 p-2 border rounded">
            <div class="d-flex justify-content-center mt-3 border rounded">
              <b-form-radio-group
                v-model="agenda.consultorio"
                class="d-flex justify-content-between"
                :options="[
                  { text: 'Clinica', value: 0 },
                  { text: 'Consultorio', value: 1 },
                ]"
                @change="
                  () => {
                    if (!!agenda.consultorio) agenda.clinica_id = null;
                  }
                "
              >
              </b-form-radio-group>
            </div>

            <div
              class="row justify-content-center"
              v-if="agenda.consultorio == 0"
            >
              <div class="col-12 mt-3">
                <h5 class="d-flex justify-item-start">Clínica</h5>
                <v-select
                  :options="clinicasOptions"
                  v-model="$v.agenda.clinica_id.$model"
                  label="label"
                  type="text"
                  :reduce="(clinica) => clinica.value"
                  :state="validateState('clinica_id')"
                >
                  <template #search="{ attributes, events }">
                    <input
                      :class="[
                        'vs__search rounded',
                        typeof validateState('clinica_id') == 'boolean' &&
                        !validateState('clinica_id')
                          ? 'isInvalid'
                          : '',
                      ]"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Nenhum resultado para <em>{{ search }}</em
                      >.
                    </template>
                    <em style="opacity: 0.5" v-else
                      >Não encontramos nenhuma clínica 😕.</em
                    >
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :force-show="
                    typeof validateState('clinica_id') == 'boolean' &&
                    !validateState('clinica_id')
                  "
                >
                  Selecione uma clínica.
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div
            class="row mt-4"
            v-if="
              especialidadeSelected &&
              especialidadeSelected.toLowerCase().indexOf('oftalmo') >= 0
            "
          >
            <div class="col-12 col-sm-10">
              <b-form-group>
                <b-form-checkbox
                  v-model="agenda.faz_dilatacao"
                  :unchecked-value="0"
                  :value="1"
                  switch
                  >Médico faz dilatação?</b-form-checkbox
                >
                <b-form-group class="mt-2">
                  <b-form-input
                    v-model="agenda.tempo_dilatacao"
                    :disabled="!agenda.faz_dilatacao"
                    type="time"
                    locale="br"
                    step="0"
                  ></b-form-input>
                  <span class="torm-text text-muted">
                    * Tempo da Dilatação</span
                  >
                </b-form-group>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-7 mt-4"></div>
          </div>
        </div>
      </div>

      <div class="row text-nowrap">
        <div class="col-12 col-sm-7">
          <b-button
            variant="primary"
            class="mt-4 justify-self-start"
            @click="showModalHorarios()"
            :disabled="loading"
          >
            <span v-if="!loading"
              ><i class="fa fa-clock"></i> Personalizar Horários</span
            >
            <span v-if="loading"
              ><b-spinner small size="sm" grow />Personalizar Horários...</span
            ></b-button
          >

          <b-modal
            id="modal-horarios-table"
            title="teste"
            size="lg"
            hide-footer
            hide-header
            no-enforce-focus
          >
            <AgendaHorariosTable
              :id_agenda="agenda.id"
              :horariosProp="agenda.horarios"
              v-on:fecharHorarios="$bvModal.hide('modal-horarios-table')"
              @personalizouHorarios="personalizarHorarios($event)"
            />
          </b-modal>
        </div>
        <div class="col-12 col-sm-5 mt-4 border rounded p-3">
          <NotificacaoConfig
            v-model="agenda.alerta_config"
            :title="'ao agendar'"
          />
        </div>
      </div>
    </b-form>
    <b-modal id="modal-logs" size="lg">
      <ExibeLogs :id_externo="agenda.id" tabela="agenda" />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import EspecialidadesLib from "@/libs/EspecialidadesLib.js";
import EntidadesLib from "@/libs/EntidadesLib.js";
import AnamneseLib from "@/libs/AnamneseLib.js";
import AgendasLib from "../../libs/AgendasLib.js";
// import Validadores from "@/helpers/Validadores";
import AgendaHorariosTable from "../Agendas/AgendaHorariosTable";
import { validationMixin } from "vuelidate";
import { required /*, minLength*/ } from "vuelidate/lib/validators";
import EmpresasLib from "../../libs/EmpresasLib";
import ExibeLogs from "@/components/common/ExibeLogs";
import NotificacaoConfig from "../Notificacao/NotificacaoConfig.vue";

export default {
  mixins: [validationMixin],
  components: {
    AgendaHorariosTable,
    ExibeLogs,
    NotificacaoConfig,
  },
  // sockets:{

  // },
  validations: {
    agenda: {
      data: { required },
      hora_inicio: { required },
      intervalo: { required },
      espera_qtd: { required },
      consulta_qtd: { required },
      retorno_qtd: { required },
      total: { required },
      // anamnese: {required},
      especialidade: { required },
      entidade_id: { required },
      clinica_id: {
        required() {
          if (this.agenda.consultorio) {
            return true;
          }
          return !!this.agenda.clinica_id;
        },
      },

      // horarios: [],
    },
  },

  props: {
    agendaEdit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      agenda: {
        data: "",
        hora_inicio: "",
        intervalo: null,
        espera_qtd: 0,
        consulta_qtd: 0,
        retorno_qtd: 0,
        total: 0,
        obs: "",
        especialidade: null,
        anamnese: null,
        entidade_id: null,
        clinica_id: null,
        faz_dilatacao: 0,
        tempo_dilatacao: "00:00",
        empresa_id: null,
        horarios: [],
        consultorio: 0,
        alerta_config: {},
      },
      horarioInicial: "",
      id_agenda: null,
      especialidadesOptions: [],
      EntidadesOptions: [],
      anamneses: [],
      clinicasOptions: [],
      loading: false,
      editando: false,
      hasErrors: [],
      personalizouHorarios: false,
      podeEditar: false,
      horariosGerados: false,
    };
  },
  methods: {
    async showModalHorarios() {
      await this.$nextTick();
      if (!this.horariosGerados) {
        this.gerarHorarios();
      }
      this.$bvModal.show("modal-horarios-table");
    },
    openLogs() {
      this.$bvModal.show("modal-logs");
    },
    verificarHorarios(evt) {
      evt;
      // console.log("evento emitdo", evt);
      // if (this.agenda.horarios.length > 0) {
      //   if (!this.podeEditar) {
      //     this.toastConfirmacao(
      //       () => {
      //         this.podeEditar = true;
      //       },
      //       () => {
      //         this.podeEditar = false;
      //         evt.preventDefault();
      //       },
      //       "Atenção, editar este campo pode causar problemas!",
      //       `Já foi gerado horários para essa agenda, mudar esse valor irá alterar os horários gerados, deseja continuar mesmo assim? Os horario excedentes serão criados ou excluidos!`,
      //       "warning",
      //       "Sim, continuar."
      //     );
      //   }
      // }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.agenda[name];
      return $dirty ? !$error : null;
    },
    validar() {
      const errors = [];
      this.$v.agenda.$touch();
      for (const name of Object.keys(this.agenda)) {
        if (this.$v.agenda[name]) {
          const { $dirty, $error } = this.$v.agenda[name];
          if ($dirty && $error) {
            let label = name.split("_");
            label = label.map((lbl) => {
              let x;
              if (lbl && lbl != "id")
                x = lbl.charAt(0).toUpperCase() + lbl.slice(1);
              return x;
            });
            // for (let lbl of label) {

            //   console.log(lbl);
            // }
            label = label.join(" ");
            const error = {
              field: name,
              label,
            };
            errors.push(error);
          }
        }
      }

      // console.log("Validou");
      return errors;
    },
    resetForm() {
      for (const key in this.agenda) {
        this.agenda[key] = null;
      }

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async carregaEspecialidades() {
      try {
        this.especialidadesOptions = await EspecialidadesLib.get();
        // .map(
        //   (e) => {
        //     return {
        //       ...e,
        //       especialidade:
        //         e.especialidade.length >= 20
        //           ? e.especialidade.substr(0, 20)
        //           : e.especialidade,
        //     };
        //   }
        // );

        // this.especialidadesOptions = especialidades.map((especialidade) => {
        //   return { value: especialidade.id, text: especialidade.especialidade };
        // });
      } catch (error) {
        console.warn(error);
      }
    },
    async carregaEntidades() {
      // console.log("chamou carregaEntidades");
      if (!this.agenda?.id) this.agenda.entidade_id = "";
      this.loading = true;
      try {
        this.EntidadesOptions = this.agenda.especialidade
          ? await EntidadesLib.getByEspecialidade(this.agenda.especialidade)
          : [];
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async carregaClinicas() {
      this.clinicasOptions = (await EmpresasLib.getEmpresasGrupo()).map(
        (emp) => {
          return {
            value: emp.id,
            label: emp.fantasia + " (" + emp.sigla + ")",
          };
        }
      );
      // this.empresa = this.empresas[0];
    },
    async carregaAnamneses() {
      this.anamneses = await AnamneseLib.get();
    },
    // async carregaHorarios() {
    //   console.log(this.agenda.id);
    // },
    gerarHorarios() {
      if (this.agenda.id) return;
      let { hora_inicio, intervalo, total } = this.agenda;
      if (!hora_inicio) return;
      let horarioAtual = hora_inicio.split(":");
      horarioAtual.splice(2, 1);
      horarioAtual = horarioAtual.join(":");

      this.horariosGerados = true;

      // console.log(
      //   "gerar novos horários? ",
      //   this.horariosFiltrados.length != total ||
      //     (this.horarioInicial != horarioAtual && isNaN(this.agenda.id)),
      //   `agendaHorarios lenth ${
      //     this.horariosFiltrados.length
      //   }, total ${total}, editouHoraInicio ${this.horarioInicial !=
      //     horarioAtual}, horaInicio ${
      //     this.horarioInicial
      //   } horaAtual ${horarioAtual}, tem id ${!isNaN(this.agenda.id)}, o id ${this.agenda.id}`
      // );

      if (
        this.horariosFiltrados.length != total ||
        this.horarioInicial != horarioAtual ||
        this.agenda.intervalo != this.agendaEdit.intervalo ||
        this.agenda.consulta_qtd != this.agendaEdit.consulta_qtd ||
        this.agenda.retorno_qtd != this.agendaEdit.retorno_qtd
      ) {
        this.horarioInicial = this.agenda.hora_inicio = horarioAtual;

        if (this.horariosFiltrados.length > 0) {
          this.agenda.horarios.forEach((_, i) => {
            this.$set(this.agenda.horarios[i], "_rowStatus", "deleted");
          });
        }

        for (let i = 0; i < this.agenda.consulta_qtd; i++) {
          const horarios = this.agenda.horarios.filter(
            (h) => h._rowStatus !== "deleted" || !h._rowStatus
          );
          let horario =
            horarios.length > 0
              ? moment(
                  horarios[horarios.length - 1].horario.replace(":", ""),
                  "hmm"
                )
                  .add(intervalo, "minutes")
                  .format("HH:mm")
              : hora_inicio;

          this.agenda.horarios.push({
            horario,
            id_paciente: null,
            paciente: null,
            telefone: null,
            idade: null,
            cpf: null,
            obs: null,
            retorno: null,
            agendado: 0,
            compareceu: 0,
            procedimento: 0,
            confirmou: 0,
            questionario: {},
            id_horario_retorno: null,
            _rowStatus: "added",
          });
        }

        for (let i = 0; i < this.agenda.retorno_qtd; i++) {
          const horarios = this.agenda.horarios.filter(
            (h) => h._rowStatus !== "deleted" || !h._rowStatus
          );
          // if(horarios.length > 0 && i === 0 )i = 1
          let horario =
            horarios.length > 0
              ? moment(
                  horarios[horarios.length - 1].horario.replace(":", ""),
                  "hmm"
                )
                  .add(intervalo, "minutes")
                  .format("HH:mm")
              : hora_inicio;
          this.agenda.horarios.push({
            horario,
            id_paciente: null,
            paciente: null,
            telefone: null,
            idade: null,
            cpf: null,
            obs: null,
            retorno: 1,
            agendado: 0,
            compareceu: 0,
            procedimento: 0,
            confirmou: 0,
            questionario: {},
            id_horario_retorno: null,
            _rowStatus: "added",
          });
        }
      }
    },
    limpa() {
      this.agenda.data = "";
      this.agenda.hora_inicio = "";
      this.agenda.intervalo = null;
      this.agenda.espera_qtd = 0;
      this.agenda.consulta_qtd = 0;
      this.agenda.retorno_qtd = 0;
      this.agenda.total = 0;
      this.agenda.obs = "";
      this.agenda.especialidade = null;
      this.agenda.entidade_id = null;
      this.agenda.clinica_id = null;
      this.agenda.empresa_id = null;
    },
    personalizarHorarios(horarios) {
      // console.log("horarios recebidos", horarios);
      this.$forceUpdate();
      this.personalizouHorarios = true;
      // this.agenda.consulta_qtd =
      //   parseInt(this.agenda.consulta_qtd) + parseInt(horarios.horariosAdded);
      this.agenda.horarios = horarios.horarios;

      const _horarios = this.agenda.horarios.filter(
        (h) => h._rowStatus !== "deleted"
      );
      this.agenda.retorno_qtd = _horarios.filter((h) => h.retorno == 1).length;
      this.agenda.consulta_qtd = _horarios.filter((h) => !h.retorno).length;
      // console.log("horarios da agenda", this.agenda.horarios);
      this.$bvModal.hide("modal-horarios-table");
    },

    showErrorSave(error = "") {
      this.$swal.fire({
        title: `Erro ao salvar! \n${error}`,
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
    async salvarAgenda() {
      // console.log("tentou salvar");
      const validation = this.validar();
      if (validation.length == 0) {
        {
          // console.log("Validou");
          if (!this.horariosGerados) this.gerarHorarios();
          // console.log("Gerou horários");
          this.loading = true;
          try {
            // console.log("Enviou para o backend");
            // console.log("ag", this.agenda);
            const data = await AgendasLib.store(this.agenda);
            // console.log("Recebeu do backend", data);
            // if (!this.editando) {
            //   console.log(data.data[0]);
            //   this.id_agenda = data.data[0];
            //   await this.$nextTick();
            // }

            // console.log(data);
            if (data && data.success) {
              this.$swal.fire({
                toast: true,
                title: "Concluído",
                text: "Agenda salva com sucesso!",
                icon: "success",
                position: "top-end",
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
              });

              this.$emit("success");
              this.$bvModal.hide("modal-agendas");
              // this.limpa();
              // this.clearErrors();
            } else {
              this.showErrorSave(data.message);
            }
          } catch (error) {
            this.showErrorSave(error);
          }
          this.loading = false;
        }
      } else {
        let errDesc = validation

          .map((e) => e.label)
          .reduce((it, vl) => {
            it += vl + " <hr />";
            return it;
          }, "");

        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
          icon: "error",
        });
      }
    },
  },

  watch: {
    agenda: {
      deep: true,
      handler() {
        if (!this.agenda || !this.agenda.alerta_config) {
          this.agenda.alerta_config = {
            tipo: "Nenhum",
            usuarios: [],
            grupo: [],
          };
        }
      },
    },
    "agenda.consulta_qtd": function (newVal) {
      this.agenda.total =
        (newVal != "" ? parseInt(newVal) : 0) +
        (this.agenda.retorno_qtd != "" ? parseInt(this.agenda.retorno_qtd) : 0);
      if (this.horariosGerados) {
        this.$swal({
          title: "Atenção",
          text: "Você já editou os horarios deseja re-criar todos os horarios?",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((r) => {
          if (r.isConfirmed) {
            this.horariosGerados = false;
            this.gerarHorarios();
          }
        });
      }
    },
    "agenda.retorno_qtd": function (value) {
      this.agenda.total =
        (value != "" ? parseInt(value) : 0) +
        (this.agenda.consulta_qtd != ""
          ? parseInt(this.agenda.consulta_qtd)
          : 0);
      if (this.horariosGerados) {
        this.$swal({
          title: "Atenção",
          text: "Você já editou os horarios deseja re-criar todos os horarios?",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((r) => {
          if (r.isConfirmed) {
            this.horariosGerados = false;
            this.gerarHorarios();
          }
        });
      }
    },
    "agenda.hora_inicio": function () {
      this.editouHoraInicio = true;
      if (this.horariosGerados && !this.agenda.id) {
        this.$swal({
          title: "Atenção",
          text: "Você já editou os horarios deseja re-criar todos os horarios?",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((r) => {
          if (r.isConfirmed) {
            this.horariosGerados = false;
            this.gerarHorarios();
          }
        });
      }
    },
    "agenda.especialidade": async function () {
      await this.carregaEntidades();
    },
    // "agenda.intervalo": function(newVal, oldVal){
    //   this.verificarHorarios()
    // },
    // agenda: {
    //   deep: true,
    //   handler() {
    //     this.validar();
    //   },
    // },
    agendaEdit() {
      if (this.$props.agendaEdit) {
        this.editando = !isNaN(this.agendaEdit.id);
        this.$props.agendaEdit.data = moment(
          this.$props.agendaEdit.data
        ).format("YYYY-MM-DD");
        // this.id_agenda = this.agendaEdit.id;
        Object.assign(this.agenda, this.agendaEdit);

        let horario = this.agenda.hora_inicio.split(":");
        horario.splice(2, 1);
        horario = horario.join(":");
        this.horarioInicial = horario;
      }
    },
  },
  created() {
    this.carregaEspecialidades();

    this.carregaClinicas();
    this.carregaAnamneses();
    this.carregaEntidades(false);
    // console.log(this.agendaEdit);
    // this.limpa();

    if (this.$props.agendaEdit) {
      this.editando = !isNaN(this.agendaEdit.id);
      this.$props.agendaEdit.data = moment(this.$props.agendaEdit.data).format(
        "YYYY-MM-DD"
      );
      // this.id_agenda = this.agendaEdit.id;
      Object.assign(this.agenda, this.agendaEdit);

      let horario = this.agenda.hora_inicio.split(":");
      horario.splice(2, 1);
      horario = horario.join(":");
      this.horarioInicial = horario;
    }
  },
  computed: {
    horariosFiltrados() {
      return this.agenda.horarios.filter((horario) => {
        if (horario._rowStatus && horario._rowStatus === "deleted") {
          return false;
        }
        return true;
      });
    },
    especialidadeSelected() {
      return this.especialidadesOptions
        .filter((espec) => espec.id == this.agenda.especialidade)
        .map((espec) => espec.especialidade)[0];
    },
    // anamnesesFiltradas(){
    //   return this.anamneses.filter(anamnese=>anamnese)
    // }
  },
};
</script>
