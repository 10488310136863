<template>
  <div>
    <div class="row">
      <div class="col-7">
        <label><b-icon-bell /> Notificar {{ title }}</label>
      </div>
      <div class="col-12 mt-2">
        <b-select
          :options="[
            'Nenhum',
            'Todos',
            'Grupo de Usuários',
            'Usuários específicos',
          ]"
          v-model="value.tipo"
        />
      </div>
      <div class="col-12 mt-2" v-if="value.tipo && value.tipo == 'Grupo de Usuários'">
        <b-select :options="tipos" text-field="valor" v-model="value.grupo" />
      </div>
      <div class="col-12 mt-2" v-if="value.tipo && value.tipo == 'Usuários específicos'">
        <v-select
          v-model="value.usuarios"
          :options="usuarios"
          label="nome"
          multiple
          :reduce="(ret) => ret.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UsuariosLib from "../../libs/UsuariosLib";
export default {
  components: {},
  props: {
    value: {
      type: Object,
    },
    title: String,
  },
  mounted() {
    this.getTipos();
    this.getUsuarios();
    if (!this.value || !this.value.tipo) {
      this.value = {
        tipo: "Nenhum",
        usuarios: [],
        grupo: [],
      };
    }
  },
  data() {
    return {
      obj: {
        ativo: false,
        tipo: "Nenhum",
        usuarios: [],
        grupo: [],
      },
      tipos: [],
      usuarios: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    async getTipos() {
      this.tipos = await UsuariosLib.getTipos();
      console.log("tipos", this.tipos);
    },
    async getUsuarios() {
      this.usuarios = await UsuariosLib.get({ativos:true});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>