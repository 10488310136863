<template>
  <div>
    <div
      class="row"
      v-for="{ key, i } of Object.keys(data).map((k, i) => ({ key: k, i }))"
      :key="key"
    >
      <div class="col ">
        <table
          class=" border m-3 p-2 shadow rounded"
          v-if="data && shouldCallRecursive(data[key])"
          :style="`margin-left: ${2 * (i + 1)}px`"
        >
          <thead>
            <tr>
              <th class="col">Campo</th>

              <th class="col">
              
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>
                <h3>
                  {{ getNamedKey(key) }}
                </h3>
              </th>

              <th>
                  <div
                  style="cursor: pointer;"
                  class="d-flex justify-content-between align-itens-"
                >
                  <b-btn
                    v-if="isObject(data[key])"
                    variant="transparent"
                    class="d-flex justify-content-between w-100 align-items-center"
                    v-b-toggle="`collapse_key_${key}_idx_${i}`"
                  >
                    <!-- <h4 class="">{{ getNamedKey(key) }}</h4> -->

                    <i class="fas fa-chevron-down"> </i>
                  </b-btn>
                </div>
              </th>
            </tr>

                <b-collapse :id="`collapse_key_${key}_idx_${i}`">
                  <TreeDados :data="data[key]" :key="`${key}_recursive`" />
                </b-collapse>
          </tbody>
        </table>

        <div
          class=" border m-3 p-2 shadow rounded"
          v-else-if="data[key].status !== 'unchanged'"
        >
          <div>
            <table>
              <thead>
                <tr>
                  <th class="col">Campo</th>
                  <th class="col">Anterior</th>
                  <th class="col">Novo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ getNamedKey(key) }}</th>
                  <td>{{ data && data[key] ? data[key].old : "Vázio" }}</td>
                  <th>{{ data && data[key] ? data[key].new : "Vázio" }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeDados",
  components: {
    TreeDados: () => () => import("./TreeDados.vue"),
  },
  props: {
    data: Object,
    namedKeys: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    shouldCallRecursive(value) {
      let result =
        value &&
        typeof value === "object" &&
        (!Object.hasOwnProperty.call(value, "old") ||
          !Object.hasOwnProperty.call(value, "new"));

      return result;
    },
    isObject(value) {
      return (
        value && typeof value === "object" && Object.keys(value).length > 0
      );
    },
    getNamedKey(key) {
      if (!Object.hasOwnProperty.call(this.namedKeys, key)) return key;
      return this.namedKeys[key];
    },
  },
};
</script>

<style></style>
